import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/sezer/Documents/Projects/talepnet-landing/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "definitions"
    }}>{`Definitions`}</h2>
    <p>{`Yazılımda yer alan satın alma yönetimi ve envanter yönetimi modüllerine ait tanımlar gerçekleştirilir. Programda tüm kullanıcılarda görüntüleme yetkisi yoktur.
Envanter Yönetimi menüsünü sadece "Depo" rolüne sahip bir pozisyona atanmış olan kullanıcılar görüntüleyebilir. Benzer şekilde "Satın Alma Yönetimi" menüsünü sadece
"Satın Alma" rolüne sahip bir pozisyona atanmış olan kullanıcılar görüntüleyebilir. Firmanın hesap sahibi kullanıcısı tüm tanım menülerinde tam yetkiye sahiptir.`}</p>
    <h2 {...{
      "id": "envanter-yönetimi"
    }}>{`Envanter Yönetimi`}</h2>
    <p>{`Depo ve stok yönetimiyle ilgili tanımların yapıldığı bölümdür. Envanter yönetimi altında ürünlerin birim kodları, ürün grupları ve ürünler tanımlanır. "Envanter Yönetimi"
menüsünü sadece "Depo" rolüne sahip bir pozisyona atanmış olan kullanıcılar görüntüleyebilir.`}</p>
    <h3 {...{
      "id": "birim-kodları"
    }}>{`Birim Kodları`}</h3>
    <p>{`Ürünler için ölçü birim kodları tanımlanır. (örnek: adet, kg) Oluşturulan birim kodları talep formu, satın alma formu, teklif ve sipariş formları, mal kabul formu,
satın alma anlaşması ve satın alma faturasında kullanılır. Ürün tanımı yapılırken her ürün için bir varsayılan birim kodu belirlenir.`}</p>
    <p>{`Birim kodu eğer diğer formlarda kullanıldıysa silme işlemine izin verilmez. Bunun yerine programda kullanılmasını artık istemediğiniz birim kodlarından aktif işaretini kaldırabilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kodu: Birim kodunu yazın.`}</li>
      <li parentName="ul">{`Adı: Birim adını yazın.`}</li>
      <li parentName="ul">{`Aktif: İşaretli olan birim kodları programda diğer formlar içinde kullanılabilir olur, aktif değilse gösterilmez.`}</li>
    </ul>
    <h3 {...{
      "id": "ürün-grupları"
    }}>{`Ürün Grupları`}</h3>
    <p>{`Ürün grup tanımları yapılır (Örnek: yiyecek, inşaat) Ürün tanımı yapılırken her ürün için bir ürün grubu seçilir.`}</p>
    <p>{`Ürün grubu eğer herhangi bir ürün içinde seçildiyse silme işlemine izin verilmez. Bunun yerine programda kullanılmasını artık istemediğiniz ürün gruplarından aktif işaretini kaldırabilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kodu: Ürün grubunun kodunu yazın.`}</li>
      <li parentName="ul">{`Adı: Ürün grubunun adını yazın.`}</li>
      <li parentName="ul">{`Aktif: İşaretli olan ürün grupları programda ürün formları içinde seçilebilir olur, aktif değilse gösterilmez.`}</li>
    </ul>
    <h3 {...{
      "id": "ürünler"
    }}>{`Ürünler`}</h3>
    <p>{`Ürün tanımları yapılır. Malzeme veya hizmet olarak firma içinde kullanıcıların talep edebileği ve satın alma yapılacak tüm ürünler oluşturulur. Tanımlanan ürünler
program içinde talep formları, satın alma formları, teklif ve sipariş formları, mal kabul formları, satın alma faturaları, satın alma anlaşmaları ile depo teslim
formlarında kullanılır.`}</p>
    <p>{`Ürün eğer yazılımda herhangi bir form içinde kullanıldıysa silme işlemine izin verilmez. Bunun yerine programda kullanılmasını artık istemediğiniz ürünlerden aktif
işaretini kaldırabilirsiniz.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
Stok takibi yapılan ürünler için programda stok hareketleri oluşur. Talep sahibine yapılan depo teslimi ve satın alma irsaliyesi (Mal kabul) işlemleri ana depoda 
stok hareketi oluşturan formlardır. Bu tip ürünlerde eğer ana depoda yeterli stok bulunmazsa depodan "Teslim Et" aksiyonu sırasında uyarı gelir ve izin verilmez. 
Stok takibi yapılmayan ürünlerde ise yazılımda stok hareketi oluşmaz ve ürün teslimi esnasında stok kontrolü yapılmadan talep sonlandırılır.
Ürün tanımlarken fiziksel olarak stok takibi istenilen ürünler için "Stok Takibi Yapılsın" işaretleyebilirsiniz.
Hizmet ürünleri için veya fiziksel olsa bile stok takibi istemediğiniz ürünler için "Stok Takibi Yapılsın" işaretini kaldırmalısınız.
    </Hint>
    <ul>
      <li parentName="ul">{`Kodu: Ürün kodunu yazın.`}</li>
      <li parentName="ul">{`Adı: Ürün adını yazın.`}</li>
      <li parentName="ul">{`Grup: Ürün grubunu seçin.`}</li>
      <li parentName="ul">{`Birim: Varsayılan birim kodunu seçin.`}</li>
      <li parentName="ul">{`KDV: Varsayılan Kdv oranını yazın.`}</li>
      <li parentName="ul">{`Stok Takibi Yapılsın: Programda ilgili üründe stok takibinin ve kontrolünün olması isteniyorsa işaretlenmelidir. Eğer hizmet ürünü ise veya fiziksel olsa dahi programda stok kontrolü ve takibi istenmeyen bir ürünse işareti kaldırılmalıdır.`}</li>
      <li parentName="ul">{`Aktif: İşaretli olan ürünler programda ilgili formlar içinde seçilebilir olur, aktif değilse gösterilmez.`}</li>
    </ul>
    <h2 {...{
      "id": "satın-alma-yönetimi"
    }}>{`Satın Alma Yönetimi`}</h2>
    <p>{`Satın alma yönetimiyle ilgili tanımların yapıldığı bölümdür. Satın alma yönetimi altında tedarikçiler ve satın alma anlaşmaları tanımlanır. "Satın Alma Yönetimi"
menüsünü sadece "Satın Alma" rolüne sahip bir pozisyona atanmış olan kullanıcılar görüntüleyebilir.`}</p>
    <h3 {...{
      "id": "tedarikçiler"
    }}>{`Tedarikçiler`}</h3>
    <p>{`Tedarikçi tanımları yapılır. Malzeme veya hizmet sağlayıcısı olan ve satın alma yapabileceğimiz tüm firmalar oluşturulur. Tanımlanan tedarikçiler program içinde satın alma teklif ve sipariş formları, mal kabul formları, satın alma faturaları ile satın alma anlaşmalarında kullanılır.`}</p>
    <p>{`Tedarikçi eğer yazılımda herhangi bir form içinde kullanıldıysa silme işlemine izin verilmez. Bunun yerine programda kullanılmasını artık istemediğiniz tedarikçilerden aktif işaretini kaldırabilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kodu: Tedarikçi kodunu yazın.`}</li>
      <li parentName="ul">{`Adı: Tedarikçi adını yazın.`}</li>
      <li parentName="ul">{`Telefon: Telefon numarasını yazın.`}</li>
      <li parentName="ul">{`Faks: Faks numarasını yazın.`}</li>
      <li parentName="ul">{`KDV: Varsayılan Kdv oranını yazın.`}</li>
      <li parentName="ul">{`Vergi Dairesi: Vergi dairesini yazın.`}</li>
      <li parentName="ul">{`Vergi Numarası: Vergi numarasını yazın.`}</li>
      <li parentName="ul">{`Adres: Tedarikçi adresini yazın.`}</li>
      <li parentName="ul">{`Şehir: Tedarikçinin şehrini seçin.`}</li>
      <li parentName="ul">{`Eposta: Tedarikçinin eposta adresini yazın.`}</li>
      <li parentName="ul">{`Aktif: İşaretli olan tedarikçiler programda ilgili formlar içinde seçilebilir olur, aktif değilse gösterilmez.`}</li>
      <li parentName="ul">{`Kişiler: Firmaya kontakt kişileri ekleyebilirsiniz. Tedarikçi firmaya eklediğiniz kontakt kişiler online satın alma teklifi ve online satın alma siparişi gönderirken email ekranlarında otomatik olarak seçilebilir olurlar.`}
        <ul parentName="li">
          <li parentName="ul">{`Adı: Kontakt adını yazın.`}</li>
          <li parentName="ul">{`Eposta: Epostasını yazın.`}</li>
          <li parentName="ul">{`Telefon: Telefonunu yazın.`}</li>
          <li parentName="ul">{`Açıklama: Notlarınızı yazabilirsiniz.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "satın-alma-anlaşmaları"
    }}>{`Satın Alma Anlaşmaları`}</h3>
    <p>{`Tedarikçi firmalarla yaptığınız satın alma anlaşmalarını tanımlarsınız. Satın alma anlaşmaları, bir tedarikçi firma ile belirli bir tarih aralığında ve belirli ürünler ile fiyatlarını ve varsa indirimlerini yazabileceğiniz formlardır.`}</p>
    <p>{`Satın alma formları oluştururken anlaşmalı satıcılarınızdan hızlıca seçim yapabilirsiniz. Eğer satın alınacak ürün için tedarikçi firmalarla anlaşmanız varsa satın alma formunda size önerilecektir.`}</p>
    <ul>
      <li parentName="ul">{`Tedarikçi: Satın alma anlaşmasının yapıldığı tedarikçiyi seçin.`}</li>
      <li parentName="ul">{`Döviz: Satın alma anlaşmasındaki ürün fiyatlarının hangi döviz tipinde olduğunu seçin.`}</li>
      <li parentName="ul">{`Başlangıç Tarihi: Tedarikçi ile anlaşmanızın başlangıç tarihini seçin.`}</li>
      <li parentName="ul">{`Bitiş Tarihi: Tedarikçi ile anlaşmanızın bitiş tarihini seçin.`}</li>
      <li parentName="ul">{`Anlaşma Satırları: Anlaşmalı olduğunuz ürünleri, fiyatlarını ve varsa indirimlerini yazın. İstediğiniz kadar ürün ekleyebilirsiniz.`}
        <ul parentName="li">
          <li parentName="ul">{`Ürün: Ürün seçin.`}</li>
          <li parentName="ul">{`Birim: Birim kodu seçin.`}</li>
          <li parentName="ul">{`Birim Fiyatı: Birim fiyatını yazın.`}</li>
          <li parentName="ul">{`İsk(%): Birinci iskonto yüzdesini yazın.`}</li>
          <li parentName="ul">{`İsk2(%): İkinci iskonto yüzdesini yazın.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      